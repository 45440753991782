export function trackPageView(url) {
  try {
    window.gtag('config', process.env.GOOGLE_ANALYTICS_ID, {
      page_location: url,
    })
  } catch (error) {
    // silences the error in dev mode
    // and/or if gtag fails to load
  }
}
