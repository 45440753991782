import App from 'next/app'
import Router from 'next/router'
import { ApolloProvider } from '@apollo/react-hooks'
import { DefaultSeo } from 'next-seo'

import withData from '../components/hoc/withData'
import ErrorBoundary from '../util/ErrorBoundary'
import { trackPageView } from '../util/analytics'

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {}
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }
    pageProps.query = ctx.query
    return { pageProps }
  }

  componentDidMount() {
    Router.onRouteChangeComplete = url => {
      trackPageView(url)
    }
  }

  render() {
    const { Component, apollo, pageProps } = this.props

    return (
      <ErrorBoundary>
        <DefaultSeo
          openGraph={{
            type: 'website',
            url: process.env.FRONTEND_URL,
            site_name: 'HOPCoin',
            title: 'HOPCoin',
            description: 'HOPCoin web app',
            images: [
              {
                url: `${process.env.FRONTEND_URL}/images/social-share-gift.png`,
                width: 1200,
                height: 630,
                alt: 'HOPCoin',
              },
            ],
          }}
          twitter={{
            handle: '@hopcoin',
            site: '@hopcoin',
            cardType: 'summary_large_image',
            image: `${process.env.FRONTEND_URL}/images/social-share-gift.png`,
          }}
        />
        <ApolloProvider client={apollo}>
          <Component {...pageProps} />
        </ApolloProvider>
      </ErrorBoundary>
    )
  }
}

export default withData(MyApp)
