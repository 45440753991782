import React from 'react'

import sentry from '../util/sentry'
const { captureException } = sentry()

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorType: null,
    }
  }

  static getDerivedStateFromError(error) {
    let errorType

    if (error.message.match(/not logged in/i)) {
      errorType = 'AUTHENTICATION'
    } else {
      errorType = 'UNKNOWN'
      console.log('Need to define new error type in Error Boundary:', errorType)
    }

    return { hasError: true, errorType }
  }

  componentDidCatch(error, info) {
    // Report all other errors to Sentry (in production):
    if (!this.state.errorType) captureException(error, { info })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Oops! Error: {this.state.errorType}</h1>
          <p>We're working on a fix!</p>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
