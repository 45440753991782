import withApollo from 'next-with-apollo'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { getDataFromTree } from '@apollo/react-ssr'

// Set API endpoint to docker when server side, in development:
const API_ENDPOINT =
  process.env.NODE_ENV === `development` && !process.browser
    ? process.env.DOCKERIZED_API_ENDPOINT
    : process.env.API_ENDPOINT

function createClient({ headers }) {
  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          )
        if (networkError) console.log(`[Network error]: ${networkError}`)
      }),
      new HttpLink({
        uri: API_ENDPOINT,
        credentials: 'include',
        headers,
      }),
    ]),

    // Disable cache:
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
    cache: new InMemoryCache(),
  })
}

export default withApollo(createClient, { getDataFromTree })
